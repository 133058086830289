import React from 'react'
import './Externo.scss'
import { useState,useEffect } from 'react'
import * as funsiones from './funsionesexterno'


import Cargando from './Cargando'
import Caducado from './Caducado'
import Visor from '../NuevoVisor/Visor'
function Externo(props) {
    const [Listo,setListo]=useState({listo:false,acceso:false})
    let contenido
    useEffect(() => {
        funsiones.acceso(props.match.params.token)
        .then((res)=>{
            console.log(res)
            if(res.estado){
                window.sessionStorage.setItem('EstudioVisor',JSON.stringify(res.estudio))
                setListo({listo:true,acceso:true})
            }else{
                setListo({listo:true,acceso:false})
            }
        })
        return () => {
            console.log('cerando')
        }
    }, [])
    if(Listo.listo){
        if(Listo.acceso){
            contenido=<Visor/>
        }else{
            contenido=<Caducado/>
        }
    }else{
        contenido=<Cargando/>
    }
    return (
        <div id="Externo">
            {contenido}
        </div>
    )
}
export default Externo
