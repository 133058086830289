import React from "react";
import * as funciones from "./funcionesCabeza.js"
import "./Cabeza.scss";

function Cabeza(props) {
  let externo = window.location.pathname.indexOf('externo')>0?true:false
  let MtInfo = ()=>{
    document.getElementById("Dialog").classList.toggle("activo")
  }
  let Hback=()=>{
    props.history.go(-1)
  }
  let abrirfilmcomposer = ()=>{
    let popup = window.open(`${window.location.origin}/filmcomposer/#${props.PrintCode}`, "FilmComposer", "width=800,height=900");
  }
  return (
    <div className="Cabeza">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "25px",
        }}
      >
        {!externo&&<i onClick={Hback}>arrow_back_ios</i>}
      </div>
      <div className="BarraHerramientas">
        <CabezaHerramientas {...props}/>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "25px",
        }}
      >
      
        {window.localStorage.getItem("filmComposerPlug")?<i onClick={abrirfilmcomposer}>print</i>:""}
        <i onClick={MtInfo}>info</i>
      </div>
    </div>
  );
}

let CabezaHerramientas = (props) => {
    let PlayCinema = ()=>{
        props.onPlay()
    }
    let HerrSel = (HerSel)=>{
        props.onHerrDesSel(HerSel)
    }
    let ImgAction = (e)=>{
        funciones.CambiarProceso(e)
    }
  return (
    <>
      <div className="Tools">
        <div className="ToolsSel Descktop">
          <BtnHer nombre="Wwwc" index={0} onClick={HerrSel} sel={props.HerrDes[0].actual==="Wwwc"?true:false} icono="contrast" />
          <BtnHer nombre="Magnify" index={0} onClick={HerrSel} sel={props.HerrDes[0].actual==="Magnify"?true:false} icono="search" />
          <BtnHer nombre="Pan" index={0} onClick={HerrSel} sel={props.HerrDes[0].actual==="Pan"?true:false} icono="pan_tool" />
          <BtnHer nombre="Rotate" index={0} onClick={HerrSel} sel={props.HerrDes[0].actual==="Rotate"?true:false} icono="screen_rotation" />
        </div>
        <div className="ToolsSel Descktop">
          <BtnHer nombre="ZoomMouseWheel" index={1} onClick={HerrSel} sel={props.HerrDes[1].actual==="ZoomMouseWheel"?true:false} icono="ZoomMouseWheel"/>
          <BtnHer nombre="SCROLL" index={1} onClick={HerrSel} sel={props.HerrDes[1].actual==="SCROLL"?true:false} icono="WEELSCROLL"/>
        </div>
        <div className="ToolsSel Descktop">
          <BtnHer nombre="Length" index={2} onClick={HerrSel} sel={props.HerrDes[2].actual==="Length"?true:false} icono="Length"/>
          <BtnHer nombre="Angle" index={2} onClick={HerrSel} sel={props.HerrDes[2].actual==="Angle"?true:false} icono="Angle"/>
          <BtnHer nombre="CobbAngle" index={2} onClick={HerrSel} sel={props.HerrDes[2].actual==="CobbAngle"?true:false} icono="CobbAngle"/>
        </div>
        <div className="ToolsSelAction">
          <BtnHerAccion movil={false} icono={props.Cinema.go?"pause":"play_arrow"} onClick={PlayCinema}/>
          <BtnHerAccion movil={true} onClick={ImgAction} icono="Vflip" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="Hflip" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="RsH" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="RaH" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="mulculo" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="pulmon" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="hueso" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="cerebro" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="SetBack" />
          <BtnHerAccion movil={true} onClick={ImgAction} icono="center" />
        </div>
      </div>
      <div className="separador"></div>
      <div className="Tools">
        {/* <BtnSys movil={true} icono="file-pdf"/>
        <BtnSys movil={false} icono="print"/> */}
        {/*<BtnSys icono="cast"/>*/}
        {/*<BtnSys icono="menu"/>*/}
      </div>
    </>
  );
};

let BtnHer = (props) => {
    let Click = ()=>{
        props.onClick({index:props.index,nombre:props.nombre})
    }
  return <div className={`BtnTool ${props.sel?"activo":""}`} onClick={Click}>
      <i>{props.icono}</i>
  </div>;
};
let BtnHerAccion = (props) => {
  return <div className={`BtnTool ${props.movil ?"":"Descktop"}`} onClick={()=>{props.onClick(props.icono)}}>
      <i>{props.icono}</i>
  </div>;
};
let BtnSys = (props) => {
  return <div className={`BtnTool ${props.movil ?"":"Descktop"}`}>
      <i>{props.icono}</i>
  </div>;
};
export default Cabeza;
