import axios from "axios"
let urlbase
class ConsultasApp{
    static Asignarurl(){
        urlbase = JSON.parse(window.localStorage.getItem("ConfiguracionGeneral")).ServidorDicom
    }
    static async Get(url,signal){
        try{
            let req = await fetch(`${urlbase}/${url}`,{signal})
            let res = await req.json()
            return res
            //return await emufetch()
        }
        catch(e){
            throw e
        }
    }
    static async Post(url,formdata){
        try{
            let res = await axios.post(`${urlbase}/${url}`,formdata)
            return res
        }
        catch(e){
            console.log(e)
            throw e
        }
    }
}
export default ConsultasApp

/*let emufetch = ()=>{
    return new Promise((Pres,Prej)=>{
        setTimeout(() => {
            Pres(DB)
        }, 200);
    })
}
let emufetch1 = ()=>{
    return new Promise((Pres,Prej)=>{
        setTimeout(() => {
            Pres(DB1)
        }, 200);
    })
}*/