import React, { useState } from "react";
import "./DownloadButton.scss";
import * as funciones from "./funciones";
import moment from "moment"
import jwt, { sign } from "jsonwebtoken"

const DownloadButton = () => {
  let COPIAR = () => {
    let IDcopy = JSON.parse(window.sessionStorage.getItem("EstudioVisor")).ID;
    let token = sign({ ID: IDcopy }, "Medicaltec3101", { expiresIn: "48h" });
    var dummy = document.createElement("input"),
      text = `${window.location.origin}/externo/${token}`;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    alert("Enlace Copiado")
  };
  return (
    <div id="DownloadButton" className="NoDescargado" onClick={COPIAR}>
      <div className="texto">COPIAR ENLACE</div>
    </div>
  );
};

export default DownloadButton;