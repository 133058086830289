import React from 'react'
import "./Drawer.scss"

function Drawer(props) {
    let CerrarDrawer = ()=>{
        props.VerDrawer(false)
    }
    return (
        <div className={`Drawer ${props.visible && "activo"}`}>
            <div className="Velo" onClick={CerrarDrawer}></div>
            <div className="DrawerBox">
                <div className="DrawerBoxArrow">
                    <span onClick={CerrarDrawer} style={{fontSize:"40px"}}>
                       <i>keyboard_arrow_down</i>
                    </span>
                </div>
                <div className="DrawerBoxContenido">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Drawer
