import React from 'react'

function Cargando() {
    return (
        <div className="Cargando">
            <div className='Logo'>
                <img src='./IMG/logoletrasC.svg'/>
            </div>
            <div className='mensaje'>
                Validando enlace
            </div>
            <div className="loader">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
            </div>
        </div>
    )
}

export default Cargando
