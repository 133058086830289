import React from 'react'
import "./Pies.scss"
function Pies(props) {
    let BtnAccion = (e,f)=>{
        if(e){
            props.onSistemAction(e,f)
        }else{
            props.onToolSelecctionMob(e,f)
        }
    }
    return (
        <div className="Pies">
            <BtnPies icono="menu" nombre="VerDrawer" sistema={true} onClick={BtnAccion}/>
            <BtnPies icono={props.Cinema.go?"pause":"play_arrow"} nombre="Cinema" sistema={true} onClick={BtnAccion}/>
            <BtnPies icono="contrast" nombre="Wwwc" seleccioando={props.HerrMobil.actual==="Wwwc"} sistema={false} onClick={BtnAccion}/>
            <BtnPies icono="Length" nombre="Length" seleccioando={props.HerrMobil.actual==="Length"} sistema={false} onClick={BtnAccion}/>
        </div>
    )
}

let BtnPies = (props)=>{
    let Click = ()=>{
        props.onClick(props.sistema,props.nombre)
    }
    return(
        <div onClick={Click} className={`BtnPies ${props.seleccioando?"activo":""}`}>
            <i>{props.icono}</i>
        </div>
    )
}
export default Pies
