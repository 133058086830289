import React from 'react'

import DownloadButton from "./DownloadButton/DownloadButton"
import Copy from "./Copy/DownloadButton"

function EstudiosBox(props) {
    let onItemClick = (e)=>{
        props.SeleccionarEstudio(e)
    }
    let Items = []
    for (let i = 0; i < props.estudio.SERIES.length; i++) {
        Items.push(<Item nombre={props.estudio.SERIES[i].NOMBRE} key={i} onClick={onItemClick} activo={props.seleccionado===i} index={i}/>)
    }
    //console.log("INFFFF"+props.VerInforme.existe)
    return (
        <div className="EstudiosBox">
        <DownloadButton/>
        <Copy/>
        {props.VerInforme.existe&&<InformeItem onClick={props.ShowInf}/>}
            {Items}
        </div>
    )
}

let Item = (props)=>{
    let Click = ()=>{
        props.onClick(props.index)
    }
    return(
        <div className={`Item ${props.activo?"activo":""}`}  onClick={Click}>
            {props.nombre}
        </div>
    )
}

let InformeItem = (props)=>{
    return(
        <div className="InformeItem" onClick={props.onClick}>
            Informe
        </div>
    )
}
export default EstudiosBox
