import React from 'react'


async function pakof(){
    let reqpako = await fetch("http://192.168.1.10:4000/getfiles2/124f0f94-1f39-4f9c-9e9d-ac344425ce84")
    console.log(reqpako.headers.get('Accept-Encoding'));
    console.log(reqpako.headers.get('Content-Length'));
}

async function dicom(){
    let reqdicom = await fetch("http://192.168.1.10:4000/getfiles/124f0f94-1f39-4f9c-9e9d-ac344425ce84")
    console.log(reqdicom.headers.get('Content-Length'));
}

function Test() {
    pakof()
    dicom()
    return (
        <div>
            test
        </div>
    )
}

export default Test
