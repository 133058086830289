import React, { useEffect, useState } from 'react'

import {Fechas,FiltroModalidades,Setting} from "./OpcionesDrawer"

import "./Drawer.scss"

function Drawer(props) {
    const [clase, setclase] = useState("activo")
    useEffect(() => {
        setclase("activo")
        if(!props.activo.visible){
            setTimeout(() => {
                setclase("")
            }, 250);
        }
        return () => {
            //cleanup
        }
    }, [props.activo.visible])
    return (
        <div id="Drawer" className={clase}>
            <Caja {...props}/>
            <Velo {...props}/>
        </div>
    )
}


export default Drawer

const Caja = (props)=>{
    let componente;
    switch (props.activo.componente) {
      case 0:
        componente = <Fechas {...props}/>;
        break;
      case 1:
        componente = <FiltroModalidades {...props}/>;
        break;
      case 2:
        componente = <Setting {...props}/>;
        break;

      default:
        break;
    }
    return(
        <div className={props.activo.visible?"Caja activo":"Caja"}>
            <div className="CerrarDrawer" onClick={()=>{props.onClose()}}>X</div>
            {componente}
        </div>
    )
}

const Velo = (props)=>{
    return(
        <div className={props.activo.visible?"Velo activo":"Velo"} onClick={()=>{props.onClose()}}></div>
    )
}