export async function BuscarInforme(){
    let configuraciones = JSON.parse( window.localStorage.getItem("ConfiguracionGeneral") )
    let estudio = JSON.parse( window.sessionStorage.getItem("EstudioVisor") )
    let infReq = await fetch(`${configuraciones.ServidorDicom}/existeinforme/${estudio.ID}`)
    let infRes = await infReq.json()
    return(infRes)
}

export async function GetInforme(nombre){
    let configuraciones = JSON.parse( window.localStorage.getItem("ConfiguracionGeneral") )
    let PDFreq = await fetch(`${configuraciones.ServidorDicom}/descargarinformever/${nombre}`)
    let PDFBlob = await PDFreq.blob()
    return(PDFBlob)
}