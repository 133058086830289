import React from "react";

import Iconos from "../ClasesFunsiones/Iconos";

import "./SideBar.scss";

function SideBar(props) {
  return (
    <div id="SideBar" className="Bg-primario">
      <AccionDrawer
        {...props}
        onClick={props.onOpen}
        componente={0}
        icon="calendario"
        color="white"
      />
      <AccionDrawer
        {...props}
        onClick={props.onOpen}
        componente={1}
        icon="filtro"
        color="white"
      />
      <AccionDrawer
        {...props}
        onClick={props.onOpen}
        componente={2}
        color="white"
      />
      <div style={{ flex: 1 }}></div>
      {props.ListaEstudios.error ? (
        <AccionSistema icon="noconexion" color="white" />
      ) : (
        <AccionSistema icon="cocnexion" color="white" />
      )}
      <AccionSistema onClick={props.onSync} icon="sync" color="white" girar={props.ListaEstudios.sync}/>
      <AccionSistema icon="logout" color="white"/>
    </div>
  );
}

export default SideBar;

export const AccionDrawer = (props) => {
    let clase = props.VerDrawer.componente===props.componente?"AccionDrawer2 activo":"AccionDrawer2"
  let click = () => {
    if (
      props.VerDrawer.visible === true &&
      props.VerDrawer.componente === props.componente
    ) {
      props.onClick({ visible: false, componente: null });
    } else {
      props.onClick({ visible: true, componente: props.componente });
    }
  };
  return (
    <div onClick={click} className="AccionDrawer">
      <div className="AccionDrawer1">
        <Iconos {...props} />
      </div>
      <div className={clase}></div>
    </div>
  );
};

export const AccionSistema = (props) => {
    let clase = props.girar?"AccionSistema girar":"AccionSistema"
  return (
    <div className={clase} onClick={props.onClick}>
        <div className="AccionSistema1">
            <Iconos {...props}/>
        </div>
    </div>
  );
};
