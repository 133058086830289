import React, { useState } from "react";
import "./DownloadButton.scss";
import * as funciones from "./funciones";
import moment from "moment"

const DownloadButton = () => {
  const [Descargando, setDescargando] = useState(false);
  const [Descargado, setDescargado] = useState(false);
  let texto;
  texto = "Descargar";
  if (Descargando) {
    texto = "Descargando";
  }
  if (Descargado) {
    texto = "Descargado";
  }
  let clase;
  clase = "NoDescargado";
  if (Descargando) {
    clase = "Descargando";
  }
  if (Descargado) {
    clase = "descargado";
  }
  let Descargar = () => {
    if (!Descargado && !Descargando) {
      setDescargando(true);
      funciones.descargarestudio()
      .then((res)=>{
        setDescargando(false);
        console.log(res.valor)
        if(res.noError){
            let A = document.createElement("a")
            A.href = URL.createObjectURL(res.valor);
            A.setAttribute('download', `${moment().format("X")}.zip`);
            A.click();
            setDescargado(true)
        }
      })

    } else {
      alert("Archivo descargado");
    }
  };
  return (
    <div id="DownloadButton" className={clase} onClick={Descargar}>
      <div className="texto">{texto}</div>
      {Descargando && (
        <div className="animacion">
          <div className="spinner" />
          <div className="Valor">0</div>
        </div>
      )}
    </div>
  );
};

export default DownloadButton;